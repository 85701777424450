import React from "react";
import "./Notification.css";
const Notification = (props) => {
  let avatar, picture;
  try {
    avatar = require(`../assets/images/${props.avatar}`);
    picture = require(`../assets/images/${props.picture}`);
  } catch {}

  return (
    <div className="notifContainer">
      <div className="notification" new={props.new ? "true" : "false"}>
        <img className="avatar" src={avatar} alt="avatar" />
        <div className="notificationBlock">
          <div className="textContainer">
            <span
              dangerouslySetInnerHTML={{
                __html: `<span class="username">${props.username}</span>${props.notificationMessage}`,
              }}
            ></span>
            {props.new && <div className="blob"></div>}
          </div>
          <div className="relativeDate">{props.relativeDate}</div>
        </div>
        {picture && (
          <img className="notifPicture" alt="comment-pic" src={picture} />
        )}
      </div>
      {props.quote && (
        <div className="quote">
          <div></div>
          <div>{props.quote}</div>
        </div>
      )}
    </div>
  );
};

export default Notification;
