import React from "react";
import "./Header.css";
const Header = (props) => {
  return (
    <div className="header">
      <div className="flex">
        <h1 className="headerTitle">Notifications</h1>
        <span className="pill">{props.count}</span>
      </div>
      <div className="markAsRead">Mark all as read</div>
    </div>
  );
};

export default Header;
