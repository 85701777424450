import "./App.css";
import Header from "./components/Header";
import Notification from "./components/Notification";

import data from "./assets/data.json";

function App() {
  return (
    <div className="container">
      <Header count={data.filter((d) => d.new).length} />
      {data.map((d) => (
        <Notification
          key={d.id}
          new={d.new}
          username={d.username}
          relativeDate={d.relativeDate}
          avatar={d.avatar}
          notificationMessage={d.notification}
          picture={d.picture}
          quote={d.quote}
        />
      ))}
    </div>
  );
}

export default App;
